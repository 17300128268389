@font-face {
    font-family: icomoonz;
    src: url(./icomoonz.woff);
}

[class*=" iconz-"], [class^=iconz-] {
    font-family: icomoonz;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 33px;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.iconz-bottle:before {
    content: "\e600";
}
.iconz-book:before {
    content: "\e601";
}
.iconz-eyes:before {
    content: "\e602";
}