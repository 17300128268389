

.domain-addition:before {
  display: block;
  content: " ";
  width: 40px;
  height: 55px;
  background-size: 40px 55px;
  background-image: url(addition.png);
}

.domain-ball:before {
  display: block;
  content: " ";
  width: 55px;
  height: 55px;
  background-size: 55px 55px;
  background-image: url(ball.png);
}

.domain-book:before {
  display: block;
  content: " ";
  width: 55px;
  height: 55px;
  background-size: 55px 55px;
  background-image: url(book.png);
}

.domain-heart:before {
  display: block;
  content: " ";
  width: 60px;
  height: 55px;
  background-size: 60px 55px;
  background-image: url(heart.png);
}
